import styled from 'styled-components';
import media from 'styles/media';

export const Wrapper = styled.section`
  display: block;
`;

export const Content = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: 'photo' 'info';
  grid-gap: 20px;
  @media ${media.small} {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'info photo';
  }
  .author-info {
    grid-area: info;
  }
  .author-photo {
    grid-area: photo;
    > div {
      max-width: 300px;
      margin: 0 auto;
    }
  }
  // IE11 targeted media query
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    div {
      &.author-info {
        flex: 70%;
      }
      &.author-photo {
        flex: 30%;
      }
    }
  }
`;
