import React from 'react';
import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Author from 'components/Sections/Author';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Author />
  </Layout>
);

export default AboutPage;
