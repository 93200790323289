import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useSpring, animated } from 'react-spring';
import { Waypoint } from 'react-waypoint';
import Heading from 'components/Heading';
import { Wrapper, Content } from './styles';

const AboutAuthor = () => {
  const [on, setOn] = useState(false);

  const fadeIn = useSpring({
    from: { opacity: 0, transform: `translate3d(-10%,0,0)` },
    opacity: on ? 1 : 0,
    transform: on ? `translate3d(0,0,0)` : `translate3d(-10%,0,0)`,
  });

  const data = useStaticQuery(graphql`
    query AUTHOR_QUERY {
      headshot: file(relativePath: { eq: "global/author-portrait.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 322, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      puppyLineup: file(relativePath: { eq: "global/puppy-lineup-lg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <Waypoint bottomOffset="35%" onEnter={() => setOn(true)} />
      <Heading text="About The Author" />
      <Content>
        <animated.div style={fadeIn} className="author-info">
          <h2>Cheri McCoy</h2>
          <p>
            At the age of forty, Cheri McCoy made a midlife career change from
            teaching elementary students and stepped into the world of grooming.
            Told that she was ‘too old’ to become a groomer, within a year she
            opened her own grooming shop and quickly earned the reputation as
            the Gentle Groomer. Over the span of the next fifteen years, her
            gentle grooming techniques changed the most fear aggressive pets
            into compliant and happily groomed clients. An insightful must-read
            for every pet owner and aspiring groomer, “Gentle Grooming” tells
            how, with determination, savvy business ideas and gentle grooming
            techniques Cheri successfully fulfilled her dream of keeping pets
            safe and calm while making them beautiful.
          </p>
          <p>
            Cheri resides in Southern California and continues to volunteer with
            animal rescue groups promoting the campaign slogan “Adopt, Don’t
            Shop” when one is looking for a family pet.
          </p>
        </animated.div>
        <div className="author-photo">
          <Img fluid={data.headshot.childImageSharp.fluid} alt="Cheri McCoy" />
        </div>
      </Content>
    </Wrapper>
  );
};

export default AboutAuthor;
